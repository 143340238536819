import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { useEvent, useStore } from "effector-react/scope";

import { Flex, SimpleGrid } from "@chakra-ui/react";

import ProjectCard from "components/project-card";

import { $searchDebaunced } from "models/search-bar";

import { fetchProjects } from "shared/api/client";
import { SearchNotFound } from "shared/ui/search-not-found";

import { IBaseProject } from "types";

import { projectListModel } from "widgets/project-list";

import {
  EmptyProjectsView,
  ErrorView,
  FetchingLayoutView,
  PageWithoutData,
  Pagination,
} from "./ui";

export const ProjectList: React.VFC = () => {
  const search = useStore($searchDebaunced);

  const page = useStore(projectListModel.$page);
  const pageSize = useStore(projectListModel.$pageSize);
  const sortBy = useStore(projectListModel.$sortBy);
  const sortOrder = useStore(projectListModel.$sortOrder);

  const handleChangePage = useEvent(projectListModel.pageChanged);
  const handleChangePageSize = useEvent(projectListModel.pageSizeChanged);

  const { data, error, isFetching } = useQuery<IBaseProject, AxiosError>(
    ["projects", page, pageSize, sortBy, sortOrder, search],
    () =>
      fetchProjects({
        filter: search,
        offset: (page - 1) * pageSize,
        limit: pageSize,
        sortBy,
        sortOrder,
      }),
    {
      refetchInterval: 10 * 1000,
      keepPreviousData: true,
    }
  );

  const isNotData = typeof data === "undefined";

  if (error) return <ErrorView httpStatus={error?.response?.status} />;
  if (isNotData) return <FetchingLayoutView />;

  if (data && data?.results.length === 0) {
    if (search) return <SearchNotFound />;
    else if (page === 1) return <EmptyProjectsView />;
    return <PageWithoutData handleBack={() => handleChangePage(1)} />;
  }

  return (
    <Flex direction="column" height="100%" mb="2rem">
      <Flex>
        <Pagination
          onChangePage={handleChangePage}
          onChangePageSize={handleChangePageSize}
          isLoading={isFetching}
          limit={pageSize}
          offset={(page - 1) * pageSize}
          total={data.total}
        />
      </Flex>
      <SimpleGrid spacing={5} minChildWidth="24rem" p={2}>
        {data?.results.map((project) => (
          <ProjectCard key={project.id} {...project} />
        ))}
      </SimpleGrid>
    </Flex>
  );
};
