import { memo, useMemo } from "react";

import { t, Trans } from "@lingui/macro";

import {
  Box,
  CircularProgress,
  Flex,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  VStack,
} from "@chakra-ui/react";

import { FiAlertTriangle } from "@react-icons/all-files/fi/FiAlertTriangle";

import NotIdealState from "components/not-ideal-state";

import { BaseProject, Model, Project } from "types";

import { FormProvider, useForm } from "react-hook-form";

import {
  ColorPicker,
  IconPicker,
  InputDescription,
  InputName,
  ModelSelectType,
  SubmitButton,
  TitleModal,
} from "./form-elements";
import { FormValues } from "./types";

type Props = {
  onSubmit: (data: FormValues) => Promise<Project> | void;
  isLoading: boolean;
  isUserStatusLoading?: boolean;
  isUserStatusSuccess?: boolean;
  isWorkflowLinkUnlinkLoading?: boolean;
  models: Model[];
  isEdit?: boolean;
  baseProject?: Project;
};

export const FormCreateEditProject: React.VFC<Props> = memo(
  ({
    onSubmit,
    isLoading,
    isUserStatusLoading,
    isUserStatusSuccess,
    isWorkflowLinkUnlinkLoading,
    models,
    isEdit,
    baseProject,
  }) => {
    const getDefaultValue = useMemo(() => {
      const isAllModelsSelected =
        baseProject?.displayProperties?.models === "all" ||
        baseProject?.displayProperties === null;

      if (isAllModelsSelected) {
        return models
          .filter((m) =>
            baseProject.workflowDefs.some((w: any) => w.id === m.id)
          )
          .map((m) => m.id);
      }

      if (Array.isArray(baseProject?.displayProperties?.models)) {
        return baseProject?.displayProperties?.models.filter((m) =>
          baseProject.workflowDefs.some((w: any) => w.id === m)
        );
      }
      return [];
    }, [baseProject, models]);

    const defaultValues =
      isEdit && baseProject
        ? {
            ...baseProject,
            displayProperties: {
              ...baseProject.displayProperties,
              models: getDefaultValue,
            },
          }
        : {
            name: "New project",
            displayProperties: {
              models: models.map((m) => m.id) || [],
            },
          };

    const methods = useForm<FormValues>({
      mode: "all",
      defaultValues,
    });

    const formSubmit = ({ displayProperties = {}, ...rest }: FormValues) => {
      onSubmit({
        ...rest,
        displayProperties: {
          ...displayProperties,
          models:
            models.length === displayProperties.models?.length
              ? "all"
              : displayProperties.models || [],
        },
      });
    };

    if (isUserStatusLoading) {
      return (
        <Box width="100%" py={8}>
          <NotIdealState spacing="4" title={t`Fetching models...`}>
            <CircularProgress capIsRound isIndeterminate />
          </NotIdealState>
        </Box>
      );
    }

    if (!isUserStatusSuccess) {
      return (
        <Box width="100%" py={8}>
          <NotIdealState
            icon={FiAlertTriangle}
            spacing="4"
            iconProps={{ color: "red.600" }}
            title={<Trans>Error</Trans>}
            description={<Trans>Could not fetch the models</Trans>}
          />
        </Box>
      );
    }

    return (
      <FormProvider {...methods}>
        <Box px={12} pt={8} pb={4} pos="relative">
          <TitleModal />
          <ModalCloseButton
            data-cy="project-form__close-button"
            m="1.9rem"
            top={0}
            right={0}
          />
        </Box>
        <ModalBody py={0} px={12}>
          <form onSubmit={methods.handleSubmit(formSubmit)}>
            <HStack spacing={8} alignItems="flex-start">
              <VStack flex="0 1 50%">
                <Box width="full" position="relative">
                  <InputName />
                  <Box position="absolute" top="0" right="0">
                    <IconPicker />
                    <ColorPicker />
                  </Box>
                </Box>
                <InputDescription />
              </VStack>
              <Flex flex="0 1 50%">
                <ModelSelectType presets={[]} models={models} isEdit={isEdit} />
              </Flex>
            </HStack>

            <ModalFooter px={0} pb={6} mt={2}>
              <SubmitButton
                isLoading={isLoading || isWorkflowLinkUnlinkLoading}
                text={isEdit ? t`Save` : t`Create`}
              />
            </ModalFooter>
          </form>
        </ModalBody>
      </FormProvider>
    );
  }
);
