import React from "react";

import { Box, Text, Tooltip, useClipboard } from "@chakra-ui/react";

export const ProjectAuthor: React.FC<{ author: string }> = ({ author }) => {
  const { onCopy, hasCopied } = useClipboard(author);

  return (
    <Box>
      <Tooltip
        aria-label="copy"
        label={hasCopied ? "Copied!" : `Click to copy email`}
        bg={hasCopied ? "green.500" : "gray.100"}
        color={hasCopied ? "white" : "black"}
      >
        <Box
          width={"fit-content"}
          onClick={(e) => {
            e.preventDefault();
            onCopy();
          }}
        >
          <Text fontSize="xs" fontWeight={600} color="gray.500">
            Author : {author}
          </Text>
          {/* {hasCopied ? (
            <Box size="20px" p="2px" as={FiCheckCircle} color="green.500" />
          ) : (
            <Avatar
              size="xs"
              onClick={(e) => {
                e.preventDefault();
                onCopy();
              }}
            />
          )} */}
        </Box>
      </Tooltip>
    </Box>
  );
};
